import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { MessageService } from 'primeng/api';
import { catchError, retry } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    public urls: any[] = [
        '/api/v1/griefs/rapportExcelUtilisateur',
        '/api/v1/griefs/rapportExcelUtilisateurFront',
        '/api/v1/griefs/rapport/ageGrief',
        '/api/v1/griefs/rapport/griefArbitragesBack',
        '/api/v1/griefs/rapport/griefArbitragesFront',
        '/api/v1/griefs/rapport/protocole'
    ];

    constructor(
        private _messageService: MessageService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.urls.some((url: any) => request.url.includes(url))) {
            return next.handle(request);
        }

        return next.handle(request).pipe(
            retry({
                delay: 1000,
                count: 3
            }),
            catchError((err: any) => {
                if (err instanceof HttpErrorResponse) {
                    try {
                        const httpError: any = err;
                        this._messageService.add({ severity: 'error', summary: 'Erreur', detail: httpError.error.error.message });
                    } catch (e: any) {
                        this._messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Une erreur est survenue' });
                    }
                } else if (err && err.status && err.status !== 500 && err.status && err.status !== 401 && err.status !== 403) {
                    return of(err);
                }
                throw new Error(err);
            })
        );
    }
}
