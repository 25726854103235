import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { OpenIdAuthGuard } from '@ngxhq/security';

import { HomeComponent } from './home/home.component';
import { LogoutComponent } from './logout/logout.component';
import { GlobalErrorComponent } from './core/errors';
import { HasPermissionForAppGuard } from './guards/has-permission-for-app.guard';

const routes: Routes = [
    {
        path: '',
        canActivate: [OpenIdAuthGuard],
        children: [
            {
                path: 'accueil',
                canActivate: [HasPermissionForAppGuard],
                component: HomeComponent,
                data: {
                    roles: ['user', 'admin', 'admin-ti', 'syndicate', 'manager']
                },
            },
            {
                path: 'griefs',
                loadChildren: () => import('./modules/grievances/grievances.module').then(m => m.GrievancesModule)
            },
            {
                path: 'dashboard',
                canActivate: [HasPermissionForAppGuard],
                data: {
                    roles: ['admin', 'admin-ti']
                },
                loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
            },
            {
                path: 'non-existing-structural-unit',
                canActivate: [HasPermissionForAppGuard],
                data: {
                    roles: ['admin', 'admin-ti', 'user']// TO-DO : CHANGE THE ROLE ACCES FOR THE GOOD ACCES
                },
                loadChildren: () => import('./modules/non-existing-structural-unit/non-existing-structural-unit.module').then(m => m.NonExistingStructuralUnitModule)
            },
            {
                path: 'document-management',
                canActivate: [HasPermissionForAppGuard],
                data: {
                    roles: ['user', 'admin', 'admin-ti', 'manager']
                },
                loadChildren: () => import('./modules/document-management/document-management.module').then(m => m.DocumentManagementModule)
            },
            {
                path: 'hr-management',
                canActivate: [HasPermissionForAppGuard],
                data: {
                    roles: ['admin', 'manager']
                },
                loadChildren: () => import('./modules/hr-management/hr-management.module').then(m => m.HrManagementModule)
            },
            {
                path: 'pilotage',
                canActivate: [HasPermissionForAppGuard],
                data: {
                    roles: ['admin', 'admin-ti']
                },
                loadChildren: () => import('./modules/systeme-control/control-system.module').then(m => m.ControlSystemModule)
            },
            {
                path: '',
                redirectTo: 'accueil',
                pathMatch: 'full'
            },
        ]
    },

    // Pages anonymes
    { path: 'deconnexion', component: LogoutComponent },
    { path: 'erreur', component: GlobalErrorComponent, data: { layout: 'fullscreen' } },
    { path: 'erreur/http/:httpCode', component: GlobalErrorComponent, data: { layout: 'fullscreen' } },

    // Défaut :
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [
        RouterModule,
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
        })
    ],
    exports: [
        RouterModule,
    ],
    declarations: [],
    providers: [],
})
export class AppRoutingModule { }
